





































import { CommonPageName } from '@projetlucie/lc-front-sdk-old';
import { version as versionPackage } from '../../package.json';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Forbidden extends Vue {
  private env = process.env.NODE_ENV;
  private version = versionPackage;
  @Prop({ required: false, default: false }) withLogin!: boolean;

  goToLogin() {
    this.$router.push(CommonPageName.Login);
  }
}
